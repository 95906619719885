import { render, staticRenderFns } from "./Donors.vue?vue&type=template&id=493ae72c&scoped=true"
import script from "./Donors.vue?vue&type=script&lang=js"
export * from "./Donors.vue?vue&type=script&lang=js"
import style0 from "./Donors.vue?vue&type=style&index=0&id=493ae72c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493ae72c",
  null
  
)

export default component.exports